<template>
<nut-navbar  @on-click-back="returnClick" title="手机快捷登录"></nut-navbar>
  <div class="content">
    <div class="blank10"></div>
    <!-- <div class="top-info">
      <nut-icon name="left" @click="returnClick"></nut-icon>
      <h4 class="big-text">手机快捷登录</h4>
    </div> -->
    <div class="blank10"></div>
    <div class="normal-formbox  newform">
      <form :model="quickForm">
        <nut-row>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入手机号"
                @change="change"
                @blur="phoneBlur"
                v-model="quickForm.phone"
                type="number"
                @clear="clear"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入验证码"
                @change="change"
                v-model="quickForm.code"
                @clear="clear"
            />
            <div class="piccode" @click="getGraphicVerificationCode()">
              <img
                  id="picCode"
                  style="height: 30px; width: 80px; cursor: pointer"
                  :src="quickForm.imgSrc"
              />
            </div>
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入手机验证码"
                @change="change"
                v-model="quickForm.phonecode"
                @clear="clear"
            />
            <div id="getPhoneCodeDiv" :style="quickForm.phone == '' ? style : ''" class="piccode">
              <nut-button plain id="getPhoneCode" type="info" size="small" @click="getPhoneCode(true)"
                          :disabled="quickForm.phone == '' ?isDisabled : false">发送验证码
              </nut-button>
            </div>
          </nut-col>
          <nut-col :span="24" class="form-item lg-btn">
            <nut-button plain type="info"  class="blue-btn" @click="quickLoginClick"
                        :loading="isLoading">
              {{loginContent}}
            </nut-button>
          </nut-col>
        </nut-row>
      </form>
    </div>
  </div>
</template>
<script>
import {getCurrentInstance, onMounted, reactive, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import axios from "axios";
import {Notify} from "@nutui/nutui";

export default {
  name: 'forgetPsw',
  setup() {
    const {proxy} = getCurrentInstance();
    const router = useRouter()
    //企业ID
    let etpId = '';
    //企业名称
    let etpName = '';
    //Base64 加密解密
    let Base64 = require('js-base64').Base64;
    //发送验证码div
    const style = {'pointer-events': 'none'};
    //发送验证码按钮
    const isDisabled = ref(true);
    //登录按钮内容
    let loginContent=ref("登录");
    //按钮不进入加载状态，可点击
    let isLoading = ref(false);

    onMounted(
        () => {
          getGraphicVerificationCode();
          timer();
        }
    )
    const quickForm = reactive({
      phone: '',
      code: '',
      imgSrc: '',
      codeAccount: '',
      phonecode: ''
    })
    // 方法调用
    const change = (value, event) => {
      console.log('change: ', value, event)
    }
    const clear = (value) => {
      console.log('clear:', value);
    };
    //废弃，获取图片验证码
    const getIdentifyingCode = (value) => {
/*      let identifyCodeSrc = 'https://www.xxx.xxx.xxx/imgCode'
      if (bRefresh) {
        identifyCodeSrc = 'https://www.xxx.xxx.xxx/imgCode?' + Math.random()
      }
      let objs = document.getElementById('imgIdentifyingCode')
      objs.src = identifyCodeSrc*/
      getGraphicVerificationCode();
    }
    //获取图形验证码
    const getGraphicVerificationCode = () => {
      const url = "https://service.sview3d.com/service/user/kaptcha";
      axios.post(url).then(
          function (res) {
            quickForm.imgSrc = 'data:image/jpg;base64,' + res.data.img;
            quickForm.codeAccount = res.data.uuid;
          }
      ).catch(res => {
            console.log("失败");
          }
      )
    }
    // 手机验证码
    const getPhoneCode = () => {
      //1、校验参数
      if (quickForm.phone == null || quickForm.phone === '') {
        Notify.warn('手机号不能为空!');
        return false;
      }
      //校验手机号
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      if (!phoneRegular.exec(quickForm.phone)) {
        Notify.warn('请输入正确的手机号!');
        return false;
      }
      if (quickForm.code == null || quickForm.code === '') {
        Notify.warn('图形验证码不能为空!');
        return false;
      }
      //2、发送请求
      const url = 'https://service.sview3d.com/service/user/getVerificationCode';
      const params = new URLSearchParams();
      params.append('phone', quickForm.phone);
      params.append('code', quickForm.code);
      params.append('account', quickForm.codeAccount);
      axios.post(url, params).then(
          function (res) {
            if (res.data.return == "0" || res.data.return == 0) {
              Notify.success("验证码已发送");
            } else {
              Notify.warn(res.data.msg);
              getGraphicVerificationCode();
            }
          }
      ).catch(res => {
            console.log("失败");
          }
      )
    }
    //手机快捷登录
    const quickLoginClick = () => {
      //1、校验参数
      if (quickForm.phone == null || quickForm.phone === '') {
        Notify.warn('手机号不能为空!');
        return false;
      }
      //校验手机号
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      if (!phoneRegular.exec(quickForm.phone)) {
        Notify.warn('请输入正确的手机号!');
        return false;
      }
      if (quickForm.code == null || quickForm.code === '') {
        Notify.warn('图形验证码不能为空!');
        return false;
      }
      //按钮进入加载状态，不可点击
      isLoading.value=true;
      loginContent.value="登录中";
      //2、发送请求
      const url = "https://service.sview3d.com/service/user/login";
      const params = new URLSearchParams();
      params.append("phone", quickForm.phone);
      params.append("type", 'phone');
      params.append("verify_code", quickForm.phonecode);
      axios.post(url, params).then(
          function (res) {
            console.log(res.data)
            if (res.data.return === '0') {
              Notify.success('欢迎登录！');
              //如果没有企业，则跳转到登记页面
              if (etpId == null || etpId === '') {
                router.push({
                  path: '/phoneWeb/CorporateInfoSet',
                  query: {
                    innerId: Base64.encode(res.data.userid),
                    phone: quickForm.phone
                  }
                });
              } else {
                //跳转到成员管理页面
                router.push({
                  path: '/phoneWeb/member',
                  query: {
                    innerId: Base64.encode(res.data.userid),
                    etpName: Base64.encode(etpName)
                  }
                });
              }
            } else {
              proxy.$dialog({
                title: '错误提示',
                content: res.data.msg,
                noCancelBtn: true
              });
              //按钮不进入加载状态，可点击
              isLoading.value=false;
              loginContent.value="登录";
            }
          }
      ).catch(
          function (error) {
            proxy.$dialog({
              title: '错误提示',
              content: error,
              noCancelBtn: true
            });
            //按钮不进入加载状态，可点击
            isLoading.value=false;
            loginContent.value="登录";
          }
      )
    }
    //返回登录页面
    const returnClick = () => {
      router.push("/phoneWeb/")
    }
    //手机号输入框失焦后执行
    const phoneBlur = (value, event) => {
      //2、查询账号所属公司
      const etpUrl = "https://work.sview3d.com/phone/getEnterpriseAdmin";
      const etpParams = new URLSearchParams();
      etpParams.append('phoneOrEmail', value);
      axios.post(etpUrl, etpParams).then(function (response) {
        console.log("-----------查询账号所属公司-------------")
        console.log(response)
        console.log("-----------查询账号所属公司 end-------------")
        let data1 = response.data;
        if (data1.success) {
          etpId = data1.innerId;
          etpName = data1.name;
        } else {
          console.log("查询账号所属公司返回的错误信息：" + data1.msg)
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
    const timer = () => {
      const getPhoneCodeDiv = document.getElementById('getPhoneCodeDiv');
      const getPhoneCode = document.getElementById('getPhoneCode');
      var timer = null;
      getPhoneCode.addEventListener('click', function () {
        //style="pointer-events: none"
        getPhoneCodeDiv.style.pointerEvents = 'none';
        isDisabled.value = true;
        let num = 60;
        getPhoneCode.innerHTML = num + 's后重新发送';
        timer = setInterval(function () {
          num--;
          getPhoneCode.innerHTML = num + 's后重新发送';
          if (num == 0) {
            clearInterval(timer);
            getPhoneCodeDiv.style.pointerEvents = '';
            isDisabled.value = false;
            getPhoneCode.innerHTML = '发送验证码';
          }
        }, 1000)
      })
    }
    return {
      quickForm,
      getIdentifyingCode,
      change,
      clear,
      getGraphicVerificationCode,
      getPhoneCode,
      returnClick,
      quickLoginClick,
      phoneBlur,
      timer,
      style,
      isDisabled,
      loginContent,
      isLoading
    }
  }
}
</script>
<style scoped>
</style>
